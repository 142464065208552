"use client";
import { ReactNode, createContext, useContext, useMemo } from "react";
import enUS from "dictionaries/en-US.json";
import jaJP from "dictionaries/ja-JP.json";
import koKR from "dictionaries/ko-KR.json";
import zhCN from "dictionaries/zh-CN.json";
import zhTW from "dictionaries/zh-TW.json";
import esMX from "dictionaries/es-MX.json";
import deDE from "dictionaries/de-DE.json";
import frCA from "dictionaries/fr-CA.json";
import ruRU from "dictionaries/ru-RU.json";
import ptBR from "dictionaries/pt-BR.json";

type Dictionary = Record<string, any>;

interface LocaleContext {
  locale: Dictionary;
  lang: string;
  country: string;
}

const defaultContext: LocaleContext = {
  locale: {},
  lang: "en",
  country: "US",
};

export const locales = [
  "en-US",
  "ja-JP",
  "ko-KR",
  "zh-CN",
  "zh-TW",
  "es-MX",
  "de-DE",
  "fr-CA",
  "ru-RU",
  "pt-BR",
] as const;

export type ValidLocale = (typeof locales)[number];

const dictionaries: Record<ValidLocale, Dictionary> = {
  "en-US": enUS,
  "ja-JP": jaJP,
  "ko-KR": koKR,
  "zh-CN": zhCN,
  "zh-TW": zhTW,
  "es-MX": esMX,
  "de-DE": deDE,
  "fr-CA": frCA,
  "ru-RU": ruRU,
  "pt-BR": ptBR,
} as const;

const LocaleContext = createContext<LocaleContext>(defaultContext);

export const useLocale = () => useContext(LocaleContext);

export function LocaleProvider({
  children,
  lang,
  country,
}: {
  children: ReactNode;
  lang: string;
  country: string;
}) {
  const value = useMemo(() => {
    const code = `${lang}-${country.toUpperCase()}` as ValidLocale;
    // console.log("locale rerendered");
    return {
      locale: dictionaries[code] || dictionaries["en-US"],
      lang,
      country,
    };
  }, [lang, country]);

  return <LocaleContext.Provider value={value}>{children}</LocaleContext.Provider>;
}
