import Modal from "@/components/shared/modal";
import {
  useState,
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
} from "react";
// import Masonry from "react-masonry-css";
import Image from "next/image";
import { generators } from "../shared/generators/generators";
import { useLocale } from "@/app/context/LocaleContext";

type StyleSelectModalProps = {
  updateStyleParam: (newStyle:any)=>void;
  showStyleSelectModal: boolean;
  setShowStyleSelectModal: Dispatch<SetStateAction<boolean>>;
};

export default function StyleSelectModal({
  updateStyleParam,
  showStyleSelectModal,
  setShowStyleSelectModal,
}: StyleSelectModalProps){
  const { locale } = useLocale();
  function selectStyle(generatorId: any) {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set("style", generatorId);
    const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
    window.history.replaceState(null, "", newUrl);
    setShowStyleSelectModal(false);
    updateStyleParam(searchParams.get("style") || "all");
  }

  // const breakpointColumnsObj = {
  //   default: 3,
  //   1124: 3,
  //   769: 3,
  //   500: 2,
  // };

  const buttonStyle = "#343639";
  const buttonClass = "border-[0.5px] border-[#3C3C3C] text-white hover:bg-gray-900 focus:outline-none focus:ring-0 focus:ring-gray-300 font-medium rounded-xl text-sm p-1.5 mx-2 mb-[14px] dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700";

  return (
    <Modal showModal={showStyleSelectModal} setShowModal={setShowStyleSelectModal}>
      <div className="flex flex-col h-[70vh] w-full overflow-hidden border-0 border-[#3C3C3C] shadow-xl sm:border-[0.5px] md:max-w-3xl md:rounded-2xl">
        <div className="flex-none border-b border-b-[0.5px] border-[#3C3C3C] bg-[#202124] px-6 py-6 text-center font-bold text-white md:px-16">
          {locale.tags.selectStyle}
        </div>
        <div className="overflow-auto bg-[#202124] p-4 grid grid-cols-3 gap-1 sm:grid-cols-3">
          {generators.map((generator: any, i: any) => (
            <button
              key={i}
              type="button"
              onClick={() => selectStyle(generator.id)}
              style={{ backgroundColor: buttonStyle }}
              className={buttonClass}
            >
              <Image
                src={generator.styleImage}
                width={500}
                height={500}
                alt={generator.styleName[Number(locale.locale.index)].value + " style image"}
                className="px-auto rounded-xl bg-[#343639] object-cover hover:brightness-105 sm:object-contain"
                placeholder="blur"
                blurDataURL="https://cdn6.imgpog.com/stylePlaceholder.webp"
                unoptimized
              />
              <div className="mt-1">
                {generator.styleName[Number(locale.locale.index)].value} 
              </div>
            </button>
          ))}
        </div>
      </div>
    </Modal>
  );
};

export function useStyleSelectModal(updateStyleParam: Dispatch<SetStateAction<any>>) {
  const [showStyleSelectModal, setShowStyleSelectModal] = useState(false);

  const StyleSelectModalCallback = useCallback(() => {
    return (
      <StyleSelectModal
        updateStyleParam={updateStyleParam}
        showStyleSelectModal={showStyleSelectModal}
        setShowStyleSelectModal={setShowStyleSelectModal}
      />
    );
  }, [showStyleSelectModal, setShowStyleSelectModal, updateStyleParam]);

  return useMemo(
    () => ({ setShowStyleSelectModal, StyleSelectModal: StyleSelectModalCallback }),
    [setShowStyleSelectModal, StyleSelectModalCallback]
  );
}
