"use client";

import { useGlobalContext } from "@/app/context/store";
import { useSignInModal } from "@/components/layout/sign-in-modal";
import { addPostsToAlbums, createAlbum, getUserAlbums } from "@/lib/api/album";
import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from "@headlessui/react";
import { FolderPlus, X } from "lucide-react";
import Image from "next/image";
import { Fragment, useEffect, useState } from "react";
import { Album } from "types/album";

interface AddToAlbumModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  postIds: string[];
  locale: any;
}

export default function AddToAlbumModal({
  isOpen,
  setIsOpen,
  postIds,
  locale,
}: AddToAlbumModalProps) {
  const { user } = useGlobalContext();
  const { SignInModal, setShowSignInModal } = useSignInModal({
    signInModalSignIn: locale.signInModalSignIn,
    signInModalSignInDescription: locale.signInModalSignInDescription,
    signInModalSignInButton: locale.signInModalSignInButton,
  });
  const [albums, setAlbums] = useState<Album[]>([]);
  const [selectedAlbumIds, setSelectedAlbumIds] = useState<string[]>([]);
  const [newAlbumName, setNewAlbumName] = useState("");
  const [newAlbumDescription, setNewAlbumDescription] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showCreateForm, setShowCreateForm] = useState(false);

  useEffect(() => {
    if (isOpen && user._id === "none") {
      setIsOpen(false);
      setShowSignInModal(true);
      return;
    }

    const fetchAlbums = async () => {
      if (user._id !== "none") {
        try {
          setIsLoading(true);
          const albums = await getUserAlbums(user._id, postIds);
          setAlbums(albums);
        } catch (error) {
          console.error("Error fetching albums:", error);
          setAlbums([]);
        } finally {
          setIsLoading(false);
        }
      }
    };
    if (isOpen) {
      fetchAlbums();
    }
  }, [user._id, isOpen, setIsOpen, setShowSignInModal]);

  const handleAddToAlbum = async () => {
    if (selectedAlbumIds.length === 0) return;
    setIsLoading(true);
    try {
      await addPostsToAlbums(selectedAlbumIds, postIds);
      setAlbums((prevAlbums) =>
        prevAlbums.map((album) =>
          selectedAlbumIds.includes(album._id) ? { ...album, containsPosts: true } : album,
        ),
      );
      setSelectedAlbumIds([]);
    } catch (error: any) {
      console.error("Error adding posts to albums:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreateAlbum = async () => {
    if (!newAlbumName) return;
    setIsLoading(true);
    try {
      const newAlbum = await createAlbum(newAlbumName, newAlbumDescription, postIds);
      setAlbums([...albums, { ...newAlbum, containsPosts: true }]);
      setShowCreateForm(false);
    } catch (error: any) {
      console.error("Error creating album:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <style jsx global>{`
        .modal-open {
          padding-right: var(--removed-body-scroll-bar-size);
        }
      `}</style>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="modal-open relative z-[1000]" onClose={() => setIsOpen(false)}>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </TransitionChild>

          <div className="fixed inset-0 overflow-x-hidden overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center sm:p-6">
              <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <DialogPanel className="w-[95%] max-w-md transform overflow-hidden rounded-2xl border border-[#3C3C3C] bg-[#2B2D31] p-4 text-left align-middle shadow-xl transition-all sm:w-full sm:p-6">
                  <div className="flex items-center justify-between mb-4">
                    <DialogTitle
                      as="h3"
                      className="text-base font-medium leading-6 text-white sm:text-lg"
                    >
                      {locale.addToAlbum}
                    </DialogTitle>
                    <button
                      onClick={() => setIsOpen(false)}
                      className="rounded-lg p-1 text-gray-400 transition-colors duration-200 hover:bg-[#202124] hover:text-white"
                    >
                      <X className="w-6 h-6" />
                    </button>
                  </div>

                  <div className="mt-4">
                    <div className="rounded-lg bg-[#1A1B1E] p-4">
                      <div className="max-h-[300px] space-y-2.5 overflow-y-auto pr-2 [&::-webkit-scrollbar-thumb:hover]:bg-[#4A4A4A] [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-thumb]:bg-[#3C3C3C] [&::-webkit-scrollbar-track]:rounded-full [&::-webkit-scrollbar-track]:bg-[#202124] [&::-webkit-scrollbar]:w-2">
                        {!showCreateForm && !isLoading && (
                          <div
                            onClick={() => setShowCreateForm(true)}
                            className="cursor-pointer rounded-lg border-2 border-dashed border-[#3C3C3C] bg-[#202124]/50 p-3 transition-all duration-200 hover:border-[#DD0772]/50 hover:bg-[#2B2D31]"
                          >
                            <div className="flex items-center text-white">
                              <FolderPlus className="w-4 h-4 mr-2" />
                              <p className="font-medium">{locale.createAlbum}</p>
                            </div>
                          </div>
                        )}
                        {showCreateForm && (
                          <div className="space-y-2.5 rounded-lg border border-[#DD0772] bg-[#DD0772]/10 p-3">
                            <input
                              type="text"
                              placeholder={locale.albumName}
                              value={newAlbumName}
                              onChange={(e) => setNewAlbumName(e.target.value)}
                              className="w-full rounded-lg border border-[#3C3C3C] bg-[#202124] px-4 py-2.5 text-white transition-all duration-200 focus:border-[#DD0772] focus:outline-none focus:ring-0"
                            />
                            <textarea
                              placeholder={locale.albumDescription}
                              value={newAlbumDescription}
                              onChange={(e) => setNewAlbumDescription(e.target.value)}
                              className="min-h-[100px] w-full resize-none rounded-lg border border-[#3C3C3C] bg-[#202124] px-4 py-2.5 text-white transition-all duration-200 focus:border-[#DD0772] focus:outline-none focus:ring-0"
                            />
                            <div className="flex justify-end gap-2">
                              <button
                                onClick={() => {
                                  setShowCreateForm(false);
                                  setNewAlbumName("");
                                  setNewAlbumDescription("");
                                }}
                                className="rounded-lg bg-[#202124] px-3 py-1.5 text-sm text-white transition-all duration-200 hover:bg-[#2B2D31]"
                              >
                                {locale.cancel}
                              </button>
                              <button
                                onClick={handleCreateAlbum}
                                disabled={!newAlbumName || isLoading}
                                className="inline-flex items-center rounded-lg bg-[#DD0772] px-3 py-1.5 text-sm text-white transition-all duration-200 hover:bg-[#DD0772]/90 disabled:cursor-not-allowed disabled:opacity-50"
                              >
                                {isLoading && (
                                  <div className="mr-2 h-3.5 w-3.5 animate-spin rounded-full border-[1.5px] border-white/20 border-t-white" />
                                )}
                                {locale.create}
                              </button>
                            </div>
                          </div>
                        )}
                        {isLoading && !showCreateForm && (
                          <div className="flex rounded-lg border border-[#3C3C3C] bg-[#202124]">
                            <Image
                              src={"https://cdn6.imgpog.com/loadingGIF.webp"}
                              width={50}
                              height={50}
                              alt="Loading"
                              className="mx-auto"
                              unoptimized
                            />
                          </div>
                        )}
                        {!isLoading &&
                          albums.map((album) => {
                            return (
                              <div
                                key={album._id}
                                onClick={() => {
                                  if (!album.containsPosts) {
                                    if (selectedAlbumIds.includes(album._id)) {
                                      setSelectedAlbumIds(
                                        selectedAlbumIds.filter((id) => id !== album._id),
                                      );
                                    } else {
                                      setSelectedAlbumIds([...selectedAlbumIds, album._id]);
                                    }
                                  }
                                }}
                                className={`cursor-pointer rounded-lg border p-3 transition-all duration-200 ${
                                  album.containsPosts
                                    ? "cursor-not-allowed border-[#DD0772] bg-[#DD0772]/10"
                                    : selectedAlbumIds.includes(album._id)
                                    ? "border-[#DD0772] bg-[#DD0772]/10"
                                    : "border-[#3C3C3C] bg-[#202124] hover:border-[#DD0772]/50 hover:bg-[#2B2D31]"
                                }`}
                              >
                                <div className="flex items-center justify-between">
                                  <p className="font-medium text-white">{album.name}</p>
                                  {album.containsPosts && (
                                    <span className="rounded bg-[#DD0772]/20 px-2 py-1 text-xs text-[#DD0772]">
                                      Added
                                    </span>
                                  )}
                                </div>
                                {/* {album.description && (
                                <p className="mt-1.5 text-sm text-gray-400 line-clamp-2">{album.description}</p>
                              )} */}
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-col justify-between gap-3 mt-6 sm:flex-row">
                    <button
                      type="button"
                      className="inline-flex h-10 w-full items-center justify-center rounded-lg border border-[#3C3C3C] bg-[#202124] px-4 text-sm font-medium text-white transition-all duration-200 hover:bg-[#2B2D31] sm:w-auto"
                      onClick={() => setIsOpen(false)}
                    >
                      {locale.cancel}
                    </button>

                    <button
                      type="button"
                      className="inline-flex h-10 w-full items-center justify-center rounded-lg border border-[#DD0772] bg-[#DD0772] px-5 text-sm font-medium text-white transition-all duration-200 hover:bg-[#DD0772]/90 focus:outline-none focus:ring-1 focus:ring-[#DD0772] disabled:cursor-not-allowed disabled:opacity-50 sm:w-auto"
                      onClick={handleAddToAlbum}
                      disabled={selectedAlbumIds.length === 0 || isLoading}
                    >
                      {isLoading ? (
                        <div className="mr-2 h-3.5 w-3.5 animate-spin rounded-full border-[1.5px] border-white/20 border-t-white" />
                      ) : null}
                      {locale.add}
                    </button>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
      <SignInModal />
    </>
  );
}
