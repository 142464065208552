import Image from "next/image";
import React, { useState } from "react";
import Link from "next/link";
import { Eye, FolderPlus } from "lucide-react";
import LikeButton from "./likeButton";
import { getLocalizedPath } from "../../shared/formatURL";
import AddToAlbumModal from "../../post/addToAlbumModal";
import { useGlobalContext } from "@/app/context/store";

interface Post {
  _id: string;
  author: string;
  createdAt: string;
  imageUrl: string;
  likes: number;
  model: string;
  tagGroups: string[];
  updatedAt: string;
  userLiked: boolean;
  views: number;
}

interface PictureCardProps {
  data: Post;
  locale: any;
  state: any;
  firstImg: number;
}

const PictureCard: React.FC<PictureCardProps> = ({ data, locale, state, firstImg }) => {
  const { _id, imageUrl, likes, userLiked, views } = data;
  const [isAddToAlbumOpen, setIsAddToAlbumOpen] = useState(false);
  const { user } = useGlobalContext();

  const newURL = getLocalizedPath("post/" + _id);

  function formatViews(number: number) {
    if (number < 1000) {
      return number.toString(); // for numbers less than 1000, just convert to string
    } else if (number < 1000000) {
      return (number / 1000).toFixed(1) + "k"; // for thousands
    } else {
      return (number / 1000000).toFixed(1) + "m"; // for millions
    }
  }

  return (
    <>
      <div
        className={`relative h-full w-full bg-[#2B2D31] ${
          state.expandContent ? "" : "sm:rounded-md"
        } shadow-sm`}
      >
        <Link prefetch={false} href={newURL}>
          {firstImg === 0 ? (
            <Image
              className={`${
                state.expandContent
                  ? ""
                  : `sm:rounded sm:border-[0.0px] sm:border-[#3C3C3C] sm:shadow`
              }`}
              src={imageUrl}
              alt={_id + " | AI Porn Generator"}
              // layout="fill" // This will make the image take the size of its parent
              // objectFit="contain"
              layout="responsive"
              width={2}
              height={3}
              loading="eager"
              priority={true}
            />
          ) : (
            <Image
              className={`${
                state.expandContent
                  ? ""
                  : `sm:rounded sm:border-[0.0px] sm:border-[#3C3C3C] sm:shadow`
              }`}
              src={imageUrl}
              alt={_id + " | AI Porn Generator"}
              // layout="fill" // This will make the image take the size of its parent
              // objectFit="contain"
              layout="responsive"
              width={2}
              height={3}
              loading="lazy"
            />
          )}
        </Link>
        {state.viewPorts.default < 5 && (
          <div className="absolute right-0 flex flex-col gap-1 bottom-2">
            <Link prefetch={false} href={newURL}>
              <div className="mx-2 flex flex-col items-center rounded-lg bg-[#2B2D31]/20 p-1 backdrop-blur-xl hover:bg-[#DD0772]">
                <Eye color="white" className="w-5 h-5" />
                <div className="text-[12px] text-white">{formatViews(views)}</div>
              </div>
            </Link>
            <LikeButton
              initialLikes={likes}
              id={_id}
              userLiked={userLiked}
              locale={locale}
              newURL={newURL}
            />
            {user._id !== "none" && !user.temp && (
              <button
                onClick={() => setIsAddToAlbumOpen(true)}
                className="mx-2 flex flex-col items-center rounded-lg bg-[#2B2D31]/20 p-1 backdrop-blur-xl hover:bg-[#DD0772]"
              >
                <FolderPlus className="w-5 h-5 text-white" />
              </button>
            )}
          </div>
        )}
      </div>
      <AddToAlbumModal
        isOpen={isAddToAlbumOpen}
        setIsOpen={setIsAddToAlbumOpen}
        postIds={[_id]}
        locale={locale}
      />
    </>
  );
};

export default PictureCard;
