"use client";

import { useState } from "react";
import {
  LayoutDashboard,
  Heart,
  LogOut,
  Gem,
  DollarSign,
  Settings,
  MessageCircleIcon,
} from "lucide-react";
import Popover from "@/components/shared/popover";
import Image from "next/image";
import { logout } from "../../lib/api/auth";
import Link from "next/link";
import { getLocalizedPath } from "../shared/formatURL";
import { useLocale } from "@/app/context/LocaleContext";

interface Props {
  user: any;
}

export default function UserDropdown({ user }: Props) {
  const { locale } = useLocale();
  const [openPopover, setOpenPopover] = useState(false);
  const localizedPath = getLocalizedPath("upgrade");

  const handlePremiumRedirect = () => {
    if (!user?.membership || user.membership?.level === 0) {
      return localizedPath + "?ref=premium";
    } else {
      return getLocalizedPath("user/premium");
    }
  };

  return (
    <div className="ml-4 inline-block flex text-left sm:ml-8">
      <Popover
        content={
          <div className="w-full rounded-md bg-[#202124] p-2 md:w-56">
            <button
              onClick={() => setOpenPopover(false)}
              className="relative flex w-full cursor-pointer items-center justify-start space-x-2 rounded-md p-2 text-left text-sm transition-all duration-75 hover:bg-[#4d4f59] focus:outline-none focus:ring-0 active:bg-[#4d4f59]"
            >
              <Link
                prefetch={false}
                href={getLocalizedPath("user/saved-posts")}
                className="flex w-full flex-row items-center text-sm"
              >
                <Heart className="mr-[8px] h-4 w-4 text-white" />
                <p className="text-sm text-white">{locale.navBar.savedPosts}</p>
              </Link>
            </button>
            <button
              onClick={() => setOpenPopover(false)}
              className="relative flex w-full cursor-pointer items-center justify-start space-x-2 rounded-md p-2 text-left text-sm transition-all duration-75 hover:bg-[#4d4f59] focus:outline-none focus:ring-0 active:bg-[#4d4f59]"
            >
              <Link
                prefetch={false}
                href={getLocalizedPath("user/albums")}
                className="flex w-full flex-row items-center text-sm"
              >
                <LayoutDashboard className="mr-[8px] h-4 w-4 text-white" />
                <p className="text-sm text-white">{locale.navBar.albums}</p>
              </Link>
            </button>
            <button
              onClick={() => setOpenPopover(false)}
              aria-label="Upgrade"
              className="relative flex w-full cursor-pointer items-center justify-start space-x-2 rounded-md p-2 text-left text-sm transition-all duration-75 hover:bg-[#4d4f59] focus:outline-none focus:ring-0 active:bg-[#4d4f59]"
            >
              <Link
                prefetch={false}
                className="flex w-full flex-row items-center text-sm"
                href={handlePremiumRedirect()}
                aria-label="Upgrade"
              >
                <Gem className="mr-[8px] h-4 w-4 text-white" />
                <p className="text-sm text-white">{locale.navBar.premium}</p>
              </Link>
            </button>
            <button
              onClick={() => setOpenPopover(false)}
              className="relative flex w-full cursor-pointer items-center justify-start space-x-2 rounded-md p-2 text-left text-sm transition-all duration-75 hover:bg-[#4d4f59] focus:outline-none focus:ring-0 active:bg-[#4d4f59]"
            >
              <a
                href="https://createaiart.zendesk.com/hc/en-us"
                target="_blank"
                rel="noopener noreferrer"
                className="flex w-full flex-row items-center text-sm"
              >
                <MessageCircleIcon className="mr-[8px] h-4 w-4 text-white" />
                <p className="text-sm text-white">{locale.navBar.helpCenter}</p>
              </a>
            </button>
            {/* <button
              onClick={() => setOpenPopover(false)}
              className="relative flex w-full cursor-pointer items-center justify-start space-x-2 rounded-md p-2 text-left text-sm transition-all duration-75 hover:bg-[#4d4f59] active:bg-[#4d4f59] focus:outline-none focus:ring-0"
            >
              <Link
                className="flex flex-row items-center w-full text-sm"
                href={
                  getLocalizedPath("user/affiliate")
                }
              >
                <DollarSign className="mr-[8px] h-4 w-4 text-white" />
                <p className="text-sm text-white">{locale.affiliate}</p>
              </Link>
            </button> */}
            <button
              onClick={() => setOpenPopover(false)}
              aria-label="Settings"
              className="relative flex w-full cursor-pointer items-center justify-start space-x-2 rounded-md p-2 text-left text-sm transition-all duration-75 hover:bg-[#4d4f59] focus:outline-none focus:ring-0 active:bg-[#4d4f59]"
            >
              <Link
                prefetch={false}
                className="flex w-full flex-row items-center text-sm"
                href={getLocalizedPath("user/settings")}
                aria-label="Settings"
              >
                <Settings className="mr-[8px] h-4 w-4 text-white" />
                <p className="text-sm text-white">{locale.navBar.settings}</p>
              </Link>
            </button>
            <button
              className="relative flex w-full items-center justify-start space-x-2 rounded-md p-2 text-left text-sm transition-all duration-75 hover:bg-[#4d4f59] focus:outline-none focus:ring-0 active:bg-[#4d4f59]"
              onClick={async () => {
                logout();
                localStorage.removeItem("jwtToken");
                window.location.href = `${process.env.NEXT_PUBLIC_BASE_URL}`;
                window.location.reload();
              }}
            >
              <LogOut className="h-4 w-4 text-white" />
              <p className="text-sm text-white">{locale.navBar.logout}</p>
            </button>
          </div>
        }
        align="end"
        openPopover={openPopover}
        setOpenPopover={setOpenPopover}
      >
        <button
          onClick={() => setOpenPopover(!openPopover)}
          className="flex h-[22.25px] w-[22.25px] items-center justify-center overflow-hidden rounded-full transition-all duration-75 focus:outline-none focus:ring-0 active:scale-95 md:h-6 md:w-6"
        >
          <Image
            alt="profile picture"
            className="my-auto"
            width={22.25}
            height={22.25}
            src={"https://cdn6.imgpog.com/profileIcon.webp"}
            unoptimized
          />
        </button>
      </Popover>
    </div>
  );
}
