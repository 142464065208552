import axiosInstance from "@/lib/axios/axiosInstance";
import { Album } from "types/album";

export async function getUserAlbums(userId: string, postIds?: string[]): Promise<Album[]> {
  const params = postIds ? { postIds: postIds.join(",") } : undefined;
  const response = await axiosInstance.get(`/album/user/${userId}`, { params });
  return response.data;
}

export async function getAlbumById(albumId: string): Promise<Album | null> {
  const response = await axiosInstance.get(`/album/${albumId}`);
  return response.data;
}

export async function addPostsToAlbums(albumIds: string[], postIds: string[]): Promise<void> {
  await axiosInstance.post(`/album/posts/add`, {
    albumIds,
    postIds,
  });
}

export async function removePostsFromAlbum(albumId: string, postIds: string[]): Promise<void> {
  await axiosInstance.delete(`/album/${albumId}/posts`, {
    data: { postIds },
  });
}

export async function reorderAlbums(albumIds: string[]): Promise<void> {
  await axiosInstance.post(`/album/reorder`, { albumIds });
}

export async function reorderAlbumPosts(albumId: string, postIds: string[]): Promise<void> {
  await axiosInstance.post(`/album/${albumId}/posts/reorder`, { postIds });
}

export async function createAlbum(
  name: string,
  description?: string,
  postIds?: string[],
): Promise<Album> {
  const response = await axiosInstance.post(`/album`, { name, description, postIds });
  return response.data;
}

export async function setAlbumCover(albumId: string, postId: string): Promise<void> {
  await axiosInstance.post(`/album/${albumId}/cover`, { postId });
}

export async function deleteAlbum(albumId: string): Promise<void> {
  await axiosInstance.delete(`/album/${albumId}`);
}

export async function updateAlbum(
  albumId: string,
  name: string,
  description: string,
): Promise<Album> {
  const response = await axiosInstance.patch(`/album/${albumId}`, { name, description });
  return response.data;
}
